@import "~antd/dist/antd.css";

body {
  font-family: "Roboto";
}

@keyframes animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
